import {AppConfig, AppName, EnvUrls} from '../types'

import {cockpit} from './cockpit'
import {easyPOM} from './easyPOM'
import {maintain} from './maintain'
import {planner} from './planner'
import {settings} from './settings'

export * from './hproduce'

export const apps: Partial<Record<AppName, AppConfig>> = {
  Planner: planner,
  Cockpit: cockpit,
  Maintain: maintain,
  Settings: settings,
  easyPOM
}

export const allApps = Object.values(apps)

export const appUrl = (urls: EnvUrls, env = process.env.REACT_APP_STAGE): string =>
  env && urls[env] ? urls[env] : urls.qa
