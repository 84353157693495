import {useRolesBusinessLogic} from '@hconnect/common/Invite/hooks/useRoles'
import {LayoutConstants, Typography} from '@hconnect/uikit'
import {Box, makeStyles} from '@material-ui/core'
import {InfoOutlined} from '@material-ui/icons'
import {Build, WarningAmberOutlined} from '@mui/icons-material'
import React from 'react'

import {MessageType, useAnnouncementMessages} from './useAnnouncementMessages'

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 32,
    verticalAlign: 'middle',
    display: 'flex',
    padding: theme.spacing(),
    lineHeight: 1.2,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  content: {
    width: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    columnGap: 4
  },
  warning: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  information: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  maintenance: {
    background: theme.palette.warning.light,
    color: theme.palette.warning.contrastText
  }
}))

export const messageTypeIcons: Record<MessageType, React.ReactNode> = {
  information: <InfoOutlined />,
  warning: <WarningAmberOutlined />,
  maintenance: <Build />
}

export const AnnouncementMessages: React.FC = () => {
  const classes = useStyle()
  const {data} = useAnnouncementMessages('Hub')
  const {roles} = useRolesBusinessLogic()

  const detectAndFormatLinks = (text: string) => {
    const urlRegex = /(\bhttps?:\/\/[^\s]+\b|\bwww\.[^\s]+\.\S{2,}\b)/g
    const emailRegex = /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z|a-z]{2,}\b)/gi

    const formatURL = (match: string) => {
      const url = match.startsWith('www') ? `https://${match}` : match
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: blue">${match}</a>`
    }

    const formatEmail = (email: string) => `<a href="mailto:${email}">${email}</a>`

    const formattedTextWithLinks = text
      .replace(urlRegex, formatURL)
      .replace(emailRegex, formatEmail)

    return formattedTextWithLinks
  }

  const warning = data?.announcements?.find(
    (x) => x.messageType === MessageType.Warning && x.isActive
  )
  const information = data?.announcements?.find(
    (x) => x.messageType === MessageType.Information && x.isActive
  )
  const maintenance = data?.announcements?.find(
    (x) => x.messageType === MessageType.Maintenance && x.isActive
  )

  const orgUnitByMessageType = (messageType: MessageType) => {
    return (
      data?.announcements?.filter(
        (x, index, array) =>
          x.messageType === messageType &&
          x.isActive &&
          x.orgUnitIds &&
          x.orgUnitIds.length > 0 &&
          roles.some((role) => x.orgUnitIds.includes(role.dataScope.orgUnitId)) &&
          index ===
            array
              .map((x) => x.orgUnitIds)
              .findIndex((p) => x.orgUnitIds.find((id) => p.includes(id)))
      ) || []
    )
  }

  const orgUnitWarning = orgUnitByMessageType(MessageType.Warning)

  const orgUnitInformation = orgUnitByMessageType(MessageType.Information)

  const orgUnitMaintenance = orgUnitByMessageType(MessageType.Maintenance)

  const announcements = [warning, information, maintenance]
  const orgUnitAnnouncements = [...orgUnitWarning, ...orgUnitInformation, ...orgUnitMaintenance]

  return (
    <>
      {(orgUnitAnnouncements.length ? orgUnitAnnouncements : announcements).map(
        (x) =>
          x && (
            <Box
              className={`${classes.root} ${classes[x.messageType]}`}
              key={x.messageId}
              data-test-id={`announcement-${x.messageId}`}
            >
              <Box className={classes.content}>
                {messageTypeIcons[x.messageType]}
                <Typography
                  variant="inherit"
                  dangerouslySetInnerHTML={{__html: detectAndFormatLinks(x.message)}}
                />
              </Box>
            </Box>
          )
      )}
    </>
  )
}
